@import '../../theme/variables';

.card {
  position: relative;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0.6rem;
  padding: 2rem 3rem;
  margin-bottom: 1.5rem;

  @media screen and (orientation: portrait) {
    width: 100rem;
    height: 19rem;
  }

  @media screen and (orientation: landscape) {
    width: 200rem;
    height: 32rem;
  }

  &__arrow {
    position: absolute;
    top: 2rem;
    fill: $color-green;
    width: 3.7rem;
    height: 2rem;

    @media screen and (orientation: portrait) {
      left: 34.8rem;
    }

    @media screen and (orientation: landscape) {
      left: 88.8rem;
    }
  }

  &__bus {
    display: flex;
    align-items: center;
    margin-right: 6rem;
  }

  &__date {
    font-weight: $font-light;

    @media screen and (orientation: portrait) {
      font-size: $font-size-l;
      line-height: $line-height-l;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xll;
      line-height: $line-height-xl;
    }
  }

  &__end {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    height: 14rem;
  }

  &__minute {
    font-weight: $font-regular;

    @media screen and (orientation: portrait) {
      font-size: 3rem;
      line-height: 4.5rem;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxxl;
      line-height: $line-height-xxl;
    }
  }

  &__numberBus {
    font-weight: $font-semiBold;
    color: $color-green;

    @media screen and (orientation: portrait) {
      font-size: 3rem;
      margin-right: 0.8rem;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxxxl;
      margin-right: 1.8rem;
    }
  }

  &__nameStation {
    font-weight: $font-bold;

    @media screen and (orientation: portrait) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxl;
      line-height: $line-height-xll;
    }
  }

  &__innerWrapper {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    @media screen and (orientation: portrait) {
      width: 7rem;
      height: 7rem;
      margin-right: 3rem;
    }

    @media screen and (orientation: landscape) {
      width: 9rem;
      height: 9rem;
      margin-right: 4rem;
    }
  }

  &__line {
    position: absolute;
    background-color: #c4c4c4;
    width: 1px;
    transform: translateY(15px);
    @media screen and (orientation: portrait) {
      left: 36.5rem;
      height: 11rem;
    }

    @media screen and (orientation: landscape) {
      left: 90.5rem;
      height: 20rem;
    }
  }

  &__time {
    font-weight: $font-semiBold;

    @media screen and (orientation: portrait) {
      font-size: $font-size-xxl;
      line-height: $line-height-xll;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxxxl;
      line-height: 9.4rem;
    }
  }

  &__station {
    @media screen and (orientation: portrait) {
      width: 20rem;
      margin-right: 1.5rem;
    }

    @media screen and (orientation: landscape) {
      width: 75rem;
      margin-right: 1.5rem;
    }
  }

  &__stationLong {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 14rem;

    @media screen and (orientation: portrait) {
      width: 33rem;
    }

    @media screen and (orientation: landscape) {
      width: 60rem;
    }

    &Time {
      @extend .card__stationLong;

      justify-content: center;
      align-items: flex-end;

      @media screen and (orientation: portrait) {
        width: 20rem;
      }

      @media screen and (orientation: landscape) {
        width: 30rem;
      }
    }
  }
}
