@import '../../../theme/variables';

.navigation {
  &__icon {
    fill: #4e4e4e;
    width: 6rem;
    height: 6rem;
  }

  &__item {
    list-style: none;
    display: flex;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 95vw;

    @media screen and (min-width: 1081px) {
      max-width: 252rem;
    }
  }

  &__listEmbedded {
    display: flex;
    justify-content: space-between;
    width: 65vw;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    font-weight: $font-semiBold;
    text-align: center;
    letter-spacing: 0.1rem;
    color: $color-grayDark;

    @media screen and (orientation: portrait) {
      font-size: $font-size-l;
      line-height: $line-height-xl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxl;
      line-height: $line-height-xxl;
    }

    &Active {
      color: $color-green;
    }

    &Disabled {
      @extend .navigation__link;
      color: $color-gray;
    }
  }

  &__underline {
    width: 100%;
    height: 0.9rem;
    background-color: $color-white;
    margin-top: $margin-m;
  }
}

.navigation__linkActive > .navigation__underline {
  background-color: $color-green;
}

.navigation__linkActive > .navigation__icon {
  fill: $color-green;
}

.navigation__linkDisabled > .navigation__icon {
  fill: $color-gray;
}
