@import '../../theme/variables';

span {
  font-weight: $font-bold;
}

.weather {
  display: flex;
  justify-content: space-between;
  width: 100rem;
  padding: 6rem 4rem;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  margin-bottom: 3rem;

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30%;

    &First {
      @extend .weather__column;

      width: 10px;
    }
  }

  &__icon {
    width: 14rem;
    height: 14rem;
  }

  &__iconSmall {
    width: 5rem;
    height: 5.2rem;
    margin-right: 1.5rem;
  }

  &__temperature {
    font-weight: 600;
    font-size: 6rem;
    line-height: 9rem;
  }

  &__paragraph {
    display: flex;
    align-items: center;
    font-weight: $font-regular;

    @media screen and (orientation: portrait) {
      font-size: $font-size-xll;
      line-height: $line-height-xl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxl;
      line-height: $line-height-xxl;
    }
  }
}

@media screen and (orientation: landscape) {
  .weather {
    width: 90vh;
  }
}
