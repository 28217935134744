@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 322rem;
  animation: fadeIn 0.5s;
}

.objects {
  margin-left: auto;
  margin-right: auto;
  height: calc(100vh - 28rem);
  overflow-y: scroll;

  &__box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 15rem;
    max-width: 105rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1081px) {
      max-width: 220rem;
    }
  }

  &__boxDetails {
    @extend .objects;

    position: relative;
    z-index: 1000;
    width: 100%;
  }

  &__button {
    display: flex;
    flex-direction: column;
    width: 104.5rem;
    height: 68rem;
    border: 0.5px solid #dde1e3;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    background-color: $color-white;
    margin-bottom: 4rem;

    @media screen and (min-width: 1081px) {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  &__buttonBack {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 2rem;
    left: 2rem;
    border: none;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    transition: all 0.2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttonBackIcon {
    fill: $color-white;
    transform: rotate(90deg);
    margin-right: 0.7rem;
    width: 4.5rem;
    height: 3rem;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__closeObjects {
    display: flex;
    width: 100%;
    height: 35vh;
    justify-content: center;
    align-items: center;
    font-size: $font-size-xxl;
    color: $color-green;
    font-weight: $font-semiBold;
  }
}
