@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

span {
  font-weight: $font-bold;
}

.object {
  display: flex;
  flex-direction: column;
  z-index: -1000;
  height: 100%;
  overflow-y: scroll;
  top: 0;
  animation: fadeIn 0.5s;

  &__descriptionBox {
    padding: 3rem 6rem;
  }

  &__imageBox {
    display: flex;
    position: relative;
    overflow-x: scroll;
    max-width: 100%;
    min-height: 33rem;
    height: 33rem;

    :last-child {
      margin-right: 0px;
    }
  }

  &__image {
    max-width: 100%;
    height: 100%;
    margin-right: 1rem;
    opacity: 1;
    transition: ease 0.5s all;

    &Hide {
      @extend .object__image;

      opacity: 0;
    }
  }

  &__imagePlaceHolder {
    min-width: 60rem;
    height: 100%;
    margin-right: 1.2rem;
    background-color: rgba(28, 100, 52, 0.1);
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: $font-size-xxl;
    line-height: $line-height-xxl;
  }

  &__category {
    font-weight: $font-semiBold;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    color: $color-blue;
    font-style: italic;
  }
}

.info {
  display: flex;
  justify-content: flex-end;
  margin: 3rem 0;
  padding-bottom: $padding-l;
  border-bottom: 1px solid #cccccc;
  position: relative;

  &__descriptionBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 5.6rem;
    height: 5.6rem;
  }

  &__logoBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #cccccc;
    margin-top: 3rem;
    padding-top: 3rem;
  }

  &__logoBoxParagraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    color: #7e8d94;
    font-style: italic;
    max-width: 21rem;
  }

  &__logoBoxLogo {
    max-width: 22rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__photo {
    margin: 2rem auto 3rem auto;
    max-width: 100%;
    max-height: 44rem;
    opacity: 1;
    transition: ease 0.5s all;

    &Hide {
      @extend .info__photo;

      opacity: 0;
    }
  }

  &__photoPlaceHolder {
    margin: 2rem auto 3rem auto;
    width: 71rem;
    height: 44rem;
    background-color: rgba(28, 100, 52, 0.1);
  }

  &__paragraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-bottom: 1rem;
  }
}

.infoRight {
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 17rem;
    margin-right: $margin-m;
  }

  &__paragraph {
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-top: $margin-s;
    text-align: center;
    color: $color-green;
  }

  &__qr {
    width: 17rem;
    height: 17rem;
  }
}

.weather {
  display: flex;
  align-items: center;
  padding-bottom: $padding-l;
  border-bottom: 1px solid #cccccc;

  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &Border {
      @extend .weather__box;

      padding-bottom: 0;
      border-bottom: none;
      border-right: 1px solid #cccccc;
    }
  }

  &__icon {
    width: 13rem;
    height: 13rem;
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: 2.4rem;
    line-height: $line-height-xxl;
    text-align: center;
  }

  &__temperature {
    margin-top: $margin-m;
    font-weight: $font-semiBold;
    font-size: $font-size-xxl;
    text-align: center;

    @media screen and (min-width: 800px) {
      font-size: $font-size-xxl;
    }
  }
}

.location {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: $font-size-xxl;
  margin-bottom: 3rem;

  &__name {
    display: flex;
    align-items: center;
    font-size: $font-size-xl;
    font-weight: $font-semiBold;
    font-style: italic;
    color: $color-green;
    text-decoration: underline;
  }

  &__icon {
    width: 4.4rem;
    height: 4.4rem;
    margin: 0 1.5rem;
  }
}

.footer {
  width: 104.2rem;
}
