@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  padding: 3rem 6rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.event {
  animation: fadeIn 0.5s;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  &__image {
    @media screen and (orientation: portrait) {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    @media screen and (orientation: landscape) {
      width: 100%;
      height: 77vh;
      object-fit: contain;
    }
  }

  &__title {
    font-weight: $font-semiBold;
    text-align: center;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    @media screen and (orientation: portrait) {
      font-size: $font-size-xxxl;
      line-height: $line-height-xxl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxxxl;
      line-height: $line-height-xxxl;
    }
  }
}

.description {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 3rem;

  &__title {
    font-weight: $font-semiBold;
    text-align: left;
    margin-bottom: 3rem;
    @media screen and (orientation: portrait) {
      font-size: $font-size-xxl;
      line-height: $line-height-xl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxxl;
      line-height: $line-height-xxl;
    }
  }

  &__subtitle {
    font-weight: $font-semiBold;

    margin-bottom: 3rem;
    text-align: left;

    @media screen and (orientation: portrait) {
      font-size: $font-size-xxl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxxl;
    }
  }

  &__paragraph {
    font-weight: $font-regular;

    text-align: left;
    @media screen and (orientation: portrait) {
      font-size: $font-size-xll;
      line-height: $line-height-xll;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxl;
      line-height: $line-height-xxl;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    font-weight: $font-semiBold;

    margin-bottom: 3rem;
    @media screen and (orientation: portrait) {
      font-size: $font-size-xll;
      line-height: $line-height-xl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxl;
      line-height: $line-height-xxl;
    }
  }

  &__icon {
    width: 5.6rem;
    height: 5.6rem;
    margin-right: 2rem;
  }
}

.footer {
  position: absolute;
  bottom: 0;
}
