@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  width: 34rem;
  height: 9.5rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.6rem;
  margin-left: 3rem;
  transition: 0.2s all;
  position: absolute;
  z-index: 2000;

  @media screen and (orientation: portrait) {
    top: 32rem;
  }

  @media screen and (orientation: landscape) {
    top: 35rem;
  }

  &Show {
    @extend .button;

    height: 59rem;
  }

  &__arrow {
    position: absolute;
    right: 0;
    fill: #1c6434;
    width: 1.8rem;
    height: 1.1rem;
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    border-top: 0.5px solid #c4c4c4;
    border-right: none;
    border-bottom: none;
    border-left: none;
    background: inherit;
    animation: fadeIn 1s;

    &First {
      @extend .button__button;

      border-top: none;
      min-height: 7rem;
    }
  }

  &__icon {
    width: 6rem;
    height: 6rem;
  }

  &__label {
    font-weight: $font-bold;
    font-size: $font-size-xll;
    line-height: $line-height-l;
    letter-spacing: 0.1rem;
    color: $color-dark;
    margin-left: 1.5rem;
    text-align: left;
    max-width: 15rem;

    &Disabled {
      @extend .button__label;

      color: $color-gray;
    }
  }
}
