@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.event {
  display: flex;
  margin-bottom: 4rem;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0.6rem;
  min-height: 44rem;
  animation: fadeIn 0.5s;
  width: 104.5rem;

  @media screen and (min-width: 1081px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  &__boxImage {
    min-width: 39rem;
    max-width: 39rem;
    height: 39rem;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: ease 0.5s all;

    &Hide {
      @extend .event__image;

      opacity: 0;
    }
  }

  &__imagePlaceHolder {
    min-width: 39rem;
    height: 39rem;
    background-color: rgba(28, 100, 52, 0.1);
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 4rem 4rem 4rem;

  &__icon {
    width: 5.6rem;
    height: 5.6rem;
    margin-right: 2rem;
  }

  &__title {
    font-weight: $font-semiBold;
    font-size: $font-size-xxxl;
    line-height: 4.5rem;
  }

  &__paragraph {
    display: flex;
    align-items: center;
    font-weight: $font-semiBold;
    font-size: $font-size-xll;
    line-height: $line-height-l;
  }
}
