@import '../../theme/variables';

.header {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.35);
  position: sticky;
  top: 0;
  z-index: 10000;
}

.headerTop {
  padding: 2rem;
  border-bottom: 0.5px solid #c4c4c4;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 322rem;
  }
}

.headerBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
}

.date {
  &__time {
    font-weight: $font-bold;
    text-align: center;
    letter-spacing: 0.1rem;

    @media screen and (orientation: portrait) {
      font-size: $font-size-xxl;
      line-height: $line-height-xxl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxxl;
      line-height: $line-height-xxl;
    }
  }

  &__date {
    font-weight: $font-regular;

    text-align: center;
    letter-spacing: 0.1rem;

    @media screen and (orientation: portrait) {
      font-size: $font-size-l;
      line-height: $line-height-l;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
    }
  }
}

.home {
  display: flex;
  align-items: center;
  background: inherit;
  border: none;
  outline: none;

  &__content {
    font-size: $font-size-l;
    font-weight: $font-semiBold;
    color: $color-grayDark;
  }

  &__icon {
    width: 5.4rem;
    height: 5rem;
    margin-right: 1rem;
  }
}

.logo {
  width: 30rem;
  height: 8rem;
  transform: translateX(5vw);

  @media screen and (min-width: 1081px) {
    transform: translateX(2vw);
  }

  &__description {
    letter-spacing: 0.2rem;
    font-size: $font-size-s;
    font-weight: $font-semiBold;
    color: $color-green;
    text-align: center;
  }

  &__logo {
    width: 30rem;
    height: 5rem;
  }
}

.weather {
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
  }

  &__icon {
    @media screen and (orientation: portrait) {
      width: 4.6rem;
      height: 4.4rem;
      margin-right: 0.5rem;
    }

    @media screen and (orientation: landscape) {
      width: 6.6rem;
      height: 6.4rem;
      margin-right: 1.5rem;
    }
  }

  &__temperature {
    font-weight: $font-bold;

    margin-right: 4.5rem;

    @media screen and (orientation: portrait) {
      font-size: 3rem;
      line-height: $line-height-xxl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxxl;
      line-height: $line-height-xxl;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    background-color: inherit;
    border: none;
    outline-style: none;
  }

  &__flag {
    width: 4.6rem;
    height: 3.8rem;
  }

  &__flagEn {
    width: 4.3rem;
    height: 4.4rem;
  }

  &__flagDisabled {
    display: none;
  }

  &__paragraph {
    font-weight: $font-semiBold;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    margin-left: 0.6rem;

    @media screen and (orientation: portrait) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
    }

    @media screen and (orientation: landscape) {
      font-size: $font-size-xxl;
      line-height: $line-height-xxl;
    }
  }
}
