@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  position: absolute;
  padding: 0 2rem;
  top: 0;
  right: 0px;
  width: 39rem;
  height: 9.5rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.6rem;
  z-index: 1000;
  transition: 0.2s all;

  &Show {
    @extend .button;

    height: 100rem;
    overflow-x: auto;
  }

  &__arrow {
    position: absolute;
    right: 0.5rem;
    fill: #1c6434;
    width: 1.8rem;
    height: 2.6rem;
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    border-top: 0.5px solid #c4c4c4;
    border-right: none;
    border-bottom: none;
    border-left: none;
    background: inherit;
    animation: fadeIn 1s;
    padding-top: 11px;

    &First {
      @extend .button__button;

      border-top: none;
      padding-top: 5px;
    }
  }

  &__label {
    font-weight: $font-bold;
    font-size: $font-size-xll;
    margin-left: 1.5rem;
    width: 25rem;
    text-align: left;
  }
}
